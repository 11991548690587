<template>
  <div class="about">
      About view
  </div>
</template>

<script>
export default {
 name: 'About',
  components: {
    
  }
}
</script>

<style>
.about {
    color: black;
}
</style>